.wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }
}

.imgBottom {
  position: absolute;
  left: calc((100% - 1595px) / 2 - 200px);
  width: 850px;
  bottom: 0;
}

.imgMobileTop {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.imgMobileBottom {
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
}

.errorPageContent {
  height: 510px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    height: 360px;
  }

  h2 {
    margin-bottom: 20px;
    font-family: Formular, sans-serif;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.43px;
    text-align: center;
    color: #222222;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

  p {
    margin-bottom: 50px;
    font-family: Inter, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: 0.51px;
    text-align: center;
    color: #868e9c;

    @media (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}

.imgBottom {
  position: absolute;
  left: calc((100% - 1595px) / 2 - 200px);
  width: 850px;
  bottom: 0;
}

.errorPageLottie {
  width: 500px;

  @media (max-width: 991px) {
    width: 240px;
  }
}
